import React from 'react'
import { Link } from 'gatsby'
import { linkToId } from '../../assets/js/utils'
import t from "../../i18n";

const FooterNavigation = ({ data, locale }) => {

    // Define translations
    const translations = t( 'Contact', locale )

    return (
        <div className='grid--four'>
            <ul className="footer__list">
                <li>
                    <h4 className='footer__title'>{data.navigationFooter1Title !== null ? data.navigationFooter1Title : ''}</h4>
                </li>
                {data.navigationFooter1 !== null &&
                    data.navigationFooter1.map((item, key) => {
                        return (
                            <li key={key}>
                                {
                                    item.pageSlug ?
                                        <a key={key} href={item.pageSlug} className='footer__link'>{item.pageFullTitle}</a> :
                                        <Link key={key} className='footer__link' to={`${locale}/${linkToId(item.id)}`}>{item.pageFullTitle}</Link>
                                }
                            </li>
                        )}
                    )
                }
            </ul>
            <ul className="footer__list">
                <li>
                    <h4 className='footer__title'>{data.navigationFooter2Title !== null ? data.navigationFooter2Title : ''}</h4>
                </li>
                {data.navigationFooter2 !== null &&
                data.navigationFooter2.map((item, key) => {
                    return (
                        <li key={key}>
                            <Link key={key} className='footer__link' to={linkToId(item.id)}>{item.pageFullTitle}</Link>
                        </li>
                    )}
                )
                }
            </ul>
            <ul className="footer__list">
                <li>
                    <h4 className='footer__title'>{data.navigationFooter3title !== null ? data.navigationFooter3title : ''}</h4>
                </li>
                {data.navigationFooter3 !== null &&
                    data.navigationFooter3.map((item, key) => {
                        return (
                            <li key={key}>
                                <Link className='footer__link' to={linkToId(item.id)}>{item.pageFullTitle}</Link>
                            </li>
                        )}
                    )
                }
                <li>
                    <a href="https://werkenbij.kraaijeveld.com/" className='footer__link'>{translations.work}</a>
                </li>
            </ul>
            <ul className="footer__list">
                <li>
                    <h4 className='footer__title'>{data.navigationFooter4title !== null ? data.navigationFooter4title : ''}</h4>
                </li>
                {data.navigationFooter4 !== null &&
                data.navigationFooter4.map((item, key) => {
                    return (
                        <li key={key}>
                            <Link key={key} className='footer__link' to={linkToId(item.id)}>{item.pageFullTitle}</Link>
                        </li>
                    )}
                )
                }
            </ul>
        </div>
    )
}

export default FooterNavigation
