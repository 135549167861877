import React from 'react'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { Link } from 'gatsby'
import FlagDE from '../../assets/images/flag_de.png'
import FlagNL from '../../assets/images/flag_nl.png'
import FlagEN from '../../assets/images/flag_en.png'
import { getUrlByLocale } from '../../assets/js/utils'

const Languages = (
    locale,
)  => {

    const { languages } = useSiteMetadata()

    return (
        <>
            <ul className='header__languages header__link'>
                {languages.translations.map((lang, label) => {
                    return (
                        <li key={label} className={locale.locale === lang.language ? 'current-language' : ''}>
                            <Link
                                to={getUrlByLocale(locale.page.contentful_id, lang.language)}
                            >
                            { lang.language === 'de-DE' &&
                                <img src={FlagDE} alt={lang.language}/>
                            }
                            { lang.language === 'en-GB' &&
                                <img src={FlagEN} alt={lang.language}/>
                            }
                            { lang.language === 'nl-NL' &&
                                <img src={FlagNL} alt={lang.language}/>
                            }
                            </Link>
                        </li>
                    )}
                )}
            </ul>
        </>
    )
}

export default Languages
