import React, { Component } from 'react'
import ClassNames from 'classnames'
import { Link } from 'gatsby'
import Navigation from './navigation'
import { window } from 'browser-monads'
import LogoSVG from '../../../static/images/logo-fullcolor.svg'
import { slide as Menu } from 'react-burger-menu'
import './header.scss'
import './mobilemenu.scss'

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true
        };
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state
        const currentScrollPos = window.pageYOffset
        const visible = prevScrollpos > currentScrollPos

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    render() {
        // Define header classes for pages other than home
        const headerClasses = this.props.type === 'homepage' ? 'header' : 'header type--page'

        // Define translations
        // const translations = t( 'Buttons', this.props.locale )

        return (
            <header className={ClassNames(headerClasses, { 'header--scroll' : ! this.state.visible })}>
                <Menu>
                    <Navigation locale={this.props.locale} navigation={this.props.navigation} page={this.props.page}/>
                </Menu>
                <div className='container'>
                    <div className='header__inner'>
                        <Link to={`/${this.props.locale.toLowerCase()}`} className='header__logo header__item--left'>
                            <LogoSVG/>
                        </Link>
                        <Navigation locale={this.props.locale} navigation={this.props.navigation} page={this.props.page}/>
                    </div>
                </div>
            </header>
        )
    }
}
