import React from 'react'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import LogoWhite from '../../../static/images/logo-white.svg'

const FooterCopyright = () => {

    // Assign Metadata
    const { siteName, siteTagline, siteUrl } = useSiteMetadata()

    // Return current year
    const currYear = new Date().getFullYear()

    return (
        <div className='footer__brand'>
            <a href={siteUrl} className='footer__brand-link'>
                <LogoWhite alt='Kraaijeveld' className='footer__logo' />
            </a>
            <span className='footer__copyright'>&copy; {siteName} - {siteTagline} | { currYear }</span>
        </div>
    )
}

export default FooterCopyright
