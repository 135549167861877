import React from 'react'
import { Link } from 'gatsby'
import Languages from './languages'
import { linkToId } from '../../assets/js/utils'
import ButtonIcon from '../../../static/images/icon-kraai.svg'
import t from '../../i18n'

const Navigation = ({
    navigation,
    locale,
    type,
    page
})  => {

    // Define translations
    const translations = t( 'Buttons', locale )

    return (
        <nav className='header__navigation'>
            { navigation !== undefined &&
                navigation.navigationHeader.map((navItem, key) => {
                    if (navItem.__typename === 'ContentfulNavigationItem')
                        // External links
                        return (
                            <a key={key} href={navItem.pageSlug} className='header__link'>{navItem.pageFullTitle}</a>
                        )
                    else {
                        // Internal links
                        return (
                            <Link
                                key={key}
                                className='header__link'
                                to={linkToId(navItem.id)}>
                                {navItem.pageFullTitle}
                            </Link>
                        )
                    }
                })
            }
            <Languages locale={locale} page={page} />
            { type === undefined &&
                // Mijn Kraaijeveld link
                <a href='https://my.kraaijeveld.com/' className='header__dashboard button button--header' target='_blank' rel='noopener noreferrer'><ButtonIcon/> { translations.mijnKraaijeveldButton }</a>
            }
        </nav>
    )

}

export default Navigation

