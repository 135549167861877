import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteName
            siteTagline
            siteUrl
            linkedin
            fulladdress
            address
            postal
            location
            email
            telephone
            fax
            languages {
              translations {
                language
                label
              }
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
