import React from 'react'
import FooterCopyright from './footerCopyright'
import FooterNavigation from './footerNavigation'
import './footer.scss'

const Footer = (props)  => {
    return (
        <>
            <footer className='footer'>
                <div className='footer__container'>
                    <div className='container'>
                        <FooterNavigation locale={props.locale} data={props.navigation}/>
                        <FooterCopyright/>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
