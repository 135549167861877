import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = (props) => {

  const pageTitle = props.page.pageFullTitle !== undefined ? `${props.page.pageFullTitle} | Kraaijeveld` : 'Kraaijeveld'

  return (
      <Helmet>
        <html lang={props.locale} />
        <title>{pageTitle}</title>
        <meta charset="UTF-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta content="width=device-width, initial-scale=1, maximum-scale=2" name="viewport" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="robots" content="noodp"/>
        {/*<meta name="description" content={pageDescription} />*/}
        {/*<meta property="og:locale" content={locale} />*/}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        {/*<meta property="og:url" content={site.siteUrl + getUrlByLocale(cfID, locale)} />*/}
        {/*<meta property="og:description" content={pageDescription} />*/}
        {/*<meta property="og:site_name" content={site.siteName} />*/}
        {/*<meta property="og:image" content={pageImage} />*/}
        {/*<meta property="og:image:width" content="1280" />*/}
        {/*<meta property="og:image:height" content="853" />*/}
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
  )
}

export default SEO

