import React from 'react';
import Header from  '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import '../assets/scss/main.scss'

const DefaultLayout = ({
    children,
    locale,
    navigation,
    type,
    page
}) => {
    return (
        <>
            <SEO page={page} locale={locale} />
            <Header locale={locale} navigation={navigation} type={type} page={page} />
            <main id='main' className='main' role='main'>
                {children}
            </main>
            <Footer locale={locale} navigation={navigation}/>
        </>
    )
}

export default DefaultLayout

