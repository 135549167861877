import enStrings from './en.json'
import deStrings from './de.json'
import nlStrings from './nl.json'

const stringTranslations = {"en": enStrings, "en-GB": enStrings, "de-DE": deStrings, "nl-NL": nlStrings}

const t = ( key, locale = 'en' ) => {

    if (!stringTranslations[locale][key]) {
        return "Error: translation not found. notify dev."
    }
    return stringTranslations[locale][key]

}

export default t
